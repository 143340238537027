import React, { useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Modal, Button } from 'react-bootstrap';
import { toggleDealerDetailsModal } from '../../redux/modules/statements';
import DealerDetailCurrentProducts from './DealerDetailCurrentProducts';
import UpgradeDealerForm from '../forms/UpgradeDealer/UpgradeDealerForm';
import * as dealerService from '../../services/dealerService';
import { createDealerProductUpgradeRequest } from '../../redux/modules/dealer';
import NotificationModal from '../../components/common/NotificationModal';

const toState = ({ statements }) => ({
  dealerDetails: statements.showDealerDetailsModal,
});

const ModalInner = ({
  dealerDetails,
  showUpgrades,
  onUpgradeClick,
  onSubmitConfirmation,
  createDealerProductUpgradeRequest,
}) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [upgradeDealer, setUpgradeDealer] = React.useState();
  const upgradeEligibleProducts = dealerService.hooks.useEligibleUpgradeProducts(dealerDetails);

  const hasNoUpgrades = Object.values(upgradeEligibleProducts).every(v =>
    Array.isArray(v) ? v.length === 0 : !v,
  );

  const handleSubmit = formValues => {
    setUpgradeDealer(formValues);
    setShowConfirmation(true);
    try {
      createDealerProductUpgradeRequest(formValues);
    } catch (err) {
      setShowConfirmation(false);
    }
  };

  return (
    <div>
      <DealerDetailCurrentProducts dealerDetails={dealerDetails} />
      <hr />
      {showUpgrades ? (
        <UpgradeDealerForm onSubmit={handleSubmit} dealerDetails={dealerDetails} />
      ) : (
        <div style={{ width: '100%' }}>
          <Button block bsStyle="success" onClick={onUpgradeClick} disabled={hasNoUpgrades}>
            Upgrade Dealer Products
          </Button>
        </div>
      )}
      <NotificationModal
        show={showConfirmation}
        title={`${upgradeDealer?.dealershipName} Submitted`}
        confirmText={'Continue to Dashboard'}
        message={
          <div>
            Forms will be sent via email from One Span Sign for signatures
            <div>
              <ul>
                <li>
                  Dealer Contact: {upgradeDealer?.documentSigner} (
                  {upgradeDealer?.dealerContactEmail})
                </li>
                <li>
                  Dealer Owner: {upgradeDealer?.ownerFirstName}
                  {upgradeDealer?.ownerLastName} ({upgradeDealer?.ownerEmail})
                </li>
              </ul>
            </div>
          </div>
        }
        onConfirm={() => {
          setShowConfirmation(false);
          onSubmitConfirmation();
        }}
      />
    </div>
  );
};

const DealerDetails = ({ dealerDetails, createDealerProductUpgradeRequest, onClose }) => {
  // hiding upgrades seems to fix the problem where the form mounts, but then destroys so validation doesnt run on Next
  const [showUpgrades, setShowUpgrades] = useState(false);

  const handleClose = () => {
    setShowUpgrades(false);
    // window.history.pushState({}, document.title, '/active-dealers');
    onClose(null);
  };

  return (
    <Modal
      show={dealerDetails != null}
      aria-labelledby="dealer-details-modal"
      bsSize="lg"
      onHide={handleClose}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">{get(dealerDetails, 'dlCompany')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {dealerDetails != null && (
          <ModalInner
            dealerDetails={dealerDetails}
            createDealerProductUpgradeRequest={createDealerProductUpgradeRequest}
            showUpgrades={showUpgrades}
            onUpgradeClick={() => setShowUpgrades(true)}
            onSubmitConfirmation={() => {
              handleClose();
            }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="danger" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(toState, {
  onClose: toggleDealerDetailsModal,
  createDealerProductUpgradeRequest,
})(DealerDetails);
