import React from 'react';
import FormBreadcrumb from '../FormBreadcrumb';
import NewDealerInformation, {
  NewDealerInformationValues,
  newDealerInformationSchema,
} from './NewDealerInformation';
import NewDealerContact, {
  NewDealerContactValues,
  newDealerContactSchema,
} from './NewDealerContact';
import NewDealerContext, {
  NewDealerContextValues,
  newDealerContextSchema,
} from './NewDealerContext';
import NewDealerProducts, {
  NewDealerProductsValues,
  newDealerProductsSchema,
} from './NewDealerProducts';
import { NewDealerPage, NewDealerFormInputsByPage, NewDealerFormInputs } from './types';
import DealerInfoCheck from '../DealerInfoCheck';
import { DealerContext, IDealerContext, useDealerContextDefaultValue } from '../DealerContext';
import FormikWizardButtons from '../FormikWizardButtons';
import { Grid } from 'react-bootstrap';
import useDealerTestData from '../useDealerTestData';
import * as dealerService from '../../../services/dealerService';
import { FinalDealerInput } from '../../../services/dealerService/hooks';

const renderActiveFormPage = ({
  activePage,
  pageValues,
  combinedValues,
  onPageSubmit,
  onBack,
}: {
  onBack: onPageChange;
} & Pick<FormData, 'activePage' | 'pageValues' | 'onPageSubmit' | 'combinedValues'>) => {
  switch (activePage) {
    case NewDealerPage.DEALER_INFORMATION:
    default:
      return (
        <NewDealerInformation
          initialValues={pageValues && pageValues[NewDealerPage.DEALER_INFORMATION]}
          onSubmit={values =>
            onPageSubmit({
              pageName: NewDealerPage.DEALER_INFORMATION,
              values,
              nextPage: NewDealerPage.DEALER_CONTACT,
            })
          }
        >
          {({ errors, isValid }) => <FormikWizardButtons disableNext={!isValid} errors={errors} />}
        </NewDealerInformation>
      );
    case NewDealerPage.DEALER_CONTACT:
      return (
        <NewDealerContact
          initialValues={pageValues && pageValues[NewDealerPage.DEALER_CONTACT]}
          onSubmit={values =>
            onPageSubmit({
              pageName: NewDealerPage.DEALER_CONTACT,
              values,
              nextPage: NewDealerPage.DEALER_CONTEXT,
            })
          }
        >
          {({ values, isValid }) => (
            <FormikWizardButtons
              disableNext={!isValid}
              onBack={() =>
                onBack({
                  from: NewDealerPage.DEALER_CONTACT,
                  to: NewDealerPage.DEALER_INFORMATION,
                  values,
                })
              }
            />
          )}
        </NewDealerContact>
      );
    case NewDealerPage.DEALER_CONTEXT:
      return (
        <NewDealerContext
          initialValues={pageValues && pageValues[NewDealerPage.DEALER_CONTEXT]}
          onSubmit={values =>
            onPageSubmit({
              pageName: NewDealerPage.DEALER_CONTEXT,
              values,
              nextPage: NewDealerPage.DEALER_PRODUCTS,
            })
          }
        >
          {({ values, isValid }) => (
            <FormikWizardButtons
              disableNext={!isValid}
              onBack={() =>
                onBack({
                  from: NewDealerPage.DEALER_CONTEXT,
                  to: NewDealerPage.DEALER_CONTACT,
                  values,
                })
              }
            />
          )}
        </NewDealerContext>
      );
    case NewDealerPage.DEALER_PRODUCTS:
      return (
        <NewDealerProducts
          initialValues={pageValues && pageValues[NewDealerPage.DEALER_PRODUCTS]}
          onSubmit={values =>
            onPageSubmit({
              pageName: NewDealerPage.DEALER_PRODUCTS,
              values,
              nextPage: NewDealerPage.DEALER_CHECK,
            })
          }
        >
          {({ values, isValid }) => (
            <FormikWizardButtons
              disableNext={!isValid}
              onBack={() =>
                onBack({
                  from: NewDealerPage.DEALER_PRODUCTS,
                  to: NewDealerPage.DEALER_CONTEXT,
                  values,
                })
              }
            />
          )}
        </NewDealerProducts>
      );
    case NewDealerPage.DEALER_CHECK:
      const combinedSchema = newDealerInformationSchema
        .concat(newDealerContactSchema)
        .concat(newDealerContextSchema)
        .concat(newDealerProductsSchema);

      return (
        <DealerInfoCheck<NewDealerFormInputs, typeof combinedSchema>
          dealerFormInputs={combinedValues}
          combinedSchema={combinedSchema}
          displayName="New Dealer Form"
        >
          {({ isValid }) => (
            <FormikWizardButtons
              disableNext={!isValid}
              nextButtonText="Submit"
              onBack={() => onBack({ to: NewDealerPage.DEALER_PRODUCTS })}
              onNext={() =>
                onPageSubmit({
                  pageName: NewDealerPage.DEALER_CHECK,
                  values: null,
                  nextPage: null,
                })
              }
            />
          )}
        </DealerInfoCheck>
      );
  }
};

interface NewDealerProps {
  initialFormValues?: NewDealerFormInputsByPage;
  initialActivePage?: NewDealerPage;
  onSubmit: (formInputs: FinalDealerInput) => Promise<void>;
}

type PageSubmit = (
  info:
    | {
        pageName: NewDealerPage.DEALER_INFORMATION;
        values: NewDealerInformationValues;
        nextPage: NewDealerPage.DEALER_CONTACT;
      }
    | {
        pageName: NewDealerPage.DEALER_CONTACT;
        values: NewDealerContactValues;
        nextPage: NewDealerPage.DEALER_CONTEXT;
      }
    | {
        pageName: NewDealerPage.DEALER_CONTEXT;
        values: NewDealerContextValues;
        nextPage: NewDealerPage.DEALER_PRODUCTS;
      }
    | {
        pageName: NewDealerPage.DEALER_PRODUCTS;
        values: NewDealerProductsValues;
        nextPage: NewDealerPage.DEALER_CHECK;
      }
    | {
        pageName: NewDealerPage.DEALER_CHECK;
        values: null;
        nextPage: null;
      },
) => Promise<void>;

type onPageChange = (backArgs: {
  from?: NewDealerPage;
  to: NewDealerPage;
  values?: NewDealerFormInputs;
}) => void;

interface FormData {
  dealerContext: IDealerContext;
  pageValues: NewDealerFormInputsByPage;
  combinedValues: NewDealerFormInputs;
  activePage: NewDealerPage;
  onPageSubmit: PageSubmit;
  onActivePageChange: onPageChange;
}

const useFormData = ({
  initialFormValues,
  initialActivePage,
  onSubmit,
}: NewDealerProps): FormData => {
  const { getNewDealerTestData } = useDealerTestData();
  const [pageValues, setPageValues] = React.useState<NewDealerFormInputsByPage>(
    initialFormValues ??
      getNewDealerTestData() ?? {
        [NewDealerPage.DEALER_INFORMATION]: {
          appointmentDate: new Date(),
        },
        [NewDealerPage.DEALER_CONTACT]: {},
        [NewDealerPage.DEALER_CONTEXT]: {},
        [NewDealerPage.DEALER_PRODUCTS]: {
          appointedProducts: [],
          supplementalProducts: [],
          isGapPlus: false,
          isBHPH: false,
          retailPack: false,
          retailPackProducts: [],
          rateType: 'STANDARD',
          includeNCBAddendum: false,
          ncbAddendumProducts: [],
          productionIncentive: false,
          productionIncentiveProducts: [],
          hasPENIntegration: false,
          dealerW9Forms: [],
        },
      },
  );
  const [activePage, setActivePage] = React.useState<NewDealerPage>(
    initialActivePage ?? NewDealerPage.DEALER_INFORMATION,
  );

  const dealerContext = useDealerContextDefaultValue({
    dealershipName: pageValues[NewDealerPage.DEALER_INFORMATION].dealershipName,
    dealerState: pageValues[NewDealerPage.DEALER_CONTACT].state,
  });

  const combinedValues = Object.values(pageValues).reduce(
    (combined, values) => ({ ...combined, ...values }),
    {},
  );

  const dealer = dealerService.hooks.useTrimUnusedDealerFields(combinedValues);
  const finalDealer = dealerService.hooks.useConvertedValues(combinedValues);

  const onPageSubmit: PageSubmit = async ({ pageName, values, nextPage }) => {
    setPageValues({ ...pageValues, [pageName]: values });
    if (nextPage) {
      setActivePage(nextPage);
    }

    if (nextPage == null && pageValues) {
      // if no next page, then its a final submittal
      // combine the form inputs with values we derived from the dealerDetails
      await onSubmit(finalDealer);
    }
  };

  const handleActivePageChange: onPageChange = ({ from, to, values }) => {
    // here we are storing partial inputs to the form - to better UX
    if (values != null && from != null) {
      setPageValues({ ...pageValues, [from]: values });
    }
    setActivePage(to);
  };

  return {
    dealerContext,
    pageValues,
    combinedValues: dealer,
    activePage,
    onPageSubmit,
    onActivePageChange: handleActivePageChange,
  };
};

export default function NewDealerForm(props: NewDealerProps) {
  const {
    dealerContext,
    pageValues,
    combinedValues,
    activePage,
    onActivePageChange,
    onPageSubmit,
  } = useFormData(props);

  return (
    <Grid>
      <DealerContext.Provider value={dealerContext}>
        <FormBreadcrumb
          items={Object.values(NewDealerPage)}
          current={activePage}
          onClick={nextPage => onActivePageChange({ to: nextPage })}
          disableLastItem={process.env.NODE_ENV !== 'development'}
        />

        {renderActiveFormPage({
          pageValues,
          combinedValues,
          activePage,
          onPageSubmit,
          onBack: onActivePageChange,
        })}
      </DealerContext.Provider>
    </Grid>
  );
}
