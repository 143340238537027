import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import qs from 'qs';
import _ from 'lodash';
import numeral from 'numeral';
import { connect, DispatchProp } from 'react-redux';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Grid, Row, Col, Alert, Button } from 'react-bootstrap';
import { DealerStatementsData, getDealerStatements } from '../../redux/modules/dealer';
import { requestImmediateStatementForOneDealer as sendDealerStatements } from '../../redux/modules/statements';


interface Params {
  dealerId: string;
}

const COLUMN_FORMATTERS = {
  balanceDue: (v: string | number) => numeral(v).format('$0,0.00'),
};

const columns = _.chain([
  'balanceDue',
  'contractType',
  'contract',
  'last6Vin',
  'customerName',
  'saleDate',
])
  .map<ColumnDescription>(col => ({
    dataField: col,
    text: _.upperCase(col),
    sort: true,
    style: { overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' },
    filter: textFilter({ placeholder: 'Filter..', delay: 500 }),
    formatter: COLUMN_FORMATTERS[col],
  }))
  .value();

interface DealerStatementsTableProps {
  statements: {}[];
}

const DealerStatementsTable: FunctionComponent<DealerStatementsTableProps> = props => {
  const { statements } = props;

  if (statements.length === 0) return <Alert className="alert-success">Dealer has no statements</Alert>;

  return (
    <div>
      {
        <BootstrapTable
          keyField="detailId"
          hover
          striped
          condensed
          rowStyle={{ cursor: 'pointer' }}
          data={statements}
          columns={columns}
          filter={filterFactory()}
          pagination={statements.length > 10 ? paginationFactory() : null}
        />
      }
    </div>
  );
};

interface Props extends DispatchProp {
  params: Params;
  getDealerStatements: Function;
  sendDealerStatements: Function;
}

export const DealerStatements: FunctionComponent<Props> = props => {
  const {
    params: { dealerId },
    getDealerStatements,
    sendDealerStatements,
  } = props;
  // TODO - Eventually, it would be good to upgrade React Router so we don't have to refer to window.location directly.
  const queryParams = qs.parse((window.location.search || '').replace(/^\?/ig, ''));

  const [data, setData] = useState<DealerStatementsData>();

  const loadData = useCallback(async () => {
    const data = await getDealerStatements({ dealerId });
    setData(data);
  }, [dealerId, getDealerStatements]);

  const onSendStatement = useCallback(() => sendDealerStatements({dealerId}), [dealerId, sendDealerStatements])

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div>
      <Helmet title={`Dealer Statements: ${dealerId}`} />
          <Grid>
            <Row className="form-list-gap">
              <Col xs={12}>
                <h3>{(queryParams || {}).dealerName}</h3>
              </Col>
              <Col xs={12}>{data && <DealerStatementsTable statements={data.statements} />}</Col>
              <Col xs={12} md={3}>
                <Button bsStyle="primary" block onClick={onSendStatement}>Send Statement to Dealer</Button>
              </Col>
            </Row>
          </Grid>
    </div>
  );
};

export default connect(null, {
  getDealerStatements,
  sendDealerStatements
})(DealerStatements);
