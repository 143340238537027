import api from '../../utils/service';
import { notifSend } from './notifs';
import { loadingCycle } from './page';

// Not connected to redux store yet because no state is used, only actions.

export const createNewDealer = data => async dispatch => {
  try {
    const { data: responseData } = await loadingCycle(
      api.post('/dealers/new', { ...data, hasNewProduct: true }),
    );
    dispatch(
      notifSend({
        kind: 'success',
        message: `${responseData.dealershipName} was created successfully, forms have been submitted for signature`,
        dismissAfter: 5000,
      }),
    );
    return responseData;
  } catch (err) {
    const { response } = err;
    dispatch(
      notifSend({
        kind: 'danger',
        message: `Failed to create dealer: ${(response.data && response.data.message) ||
          err.toString()}`,
        dismissAfter: 6000,
      }),
    );
    throw err;
  }
};

export const createDealerProductUpgradeRequest = data => async dispatch => {
  try {
    const { data: responseData } = await loadingCycle(api.post('/dealers/upgrade', data));
    dispatch(
      notifSend({
        kind: 'success',
        message: `${responseData.dealershipName} was created successfully, forms have been submitted for signature`,
        dismissAfter: 5000,
      }),
    );
    return responseData;
  } catch (err) {
    const { response } = err;
    dispatch(
      notifSend({
        kind: 'danger',
        message: `Failed to create dealer: ${(response && response.data && response.data.message) ||
          err.toString()}`,
        dismissAfter: 6000,
      }),
    );
    throw err;
  }
};

export interface DealerStatementsData {
  details: {
    dealerId: string;
    dlCompany: string;
  };
  statements: {}[];
}

export const getDealerStatements = ({ dealerId }) => async dispatch => {
  try {
    const { data: {details, statements} } = await loadingCycle(api.get(`/dealers/${dealerId}/statements`));
    return {details, statements} as DealerStatementsData;
  } catch (err) {
    const { response } = err;
    dispatch(
      notifSend({
        kind: 'danger',
        message: `Failed to get dealer statements: ${(response && response.data && response.data.message) ||
          err.toString()}`,
        dismissAfter: 6000,
      }),
    );
    throw err;
  }
};
