import React, { useState, useCallback, useMemo } from 'react';
import {
  FormControl,
  FormGroup,
  ControlLabel,
  InputGroup,
  HelpBlock,
  Button,
  Alert,
  ProgressBar,
} from 'react-bootstrap';
import api from '../utils/service';

// @deprecated TODO delete when Auth0 migration is over.
// TODO if it's not 2021, it's time to delete it.

// eslint-disable-next-line max-len, no-control-regex
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const MigrateFrom219ApiToAuth0 = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState(undefined);
  const [lastError, setLastError] = useState();
  const [inProgress, setInProgress] = useState(false);

  const emailError = EMAIL_REGEX.test(email) ? undefined : 'Invalid email';
  const newPasswordError =
    newPassword === undefined
      ? undefined
      : (() => {
          if (newPassword.length < 8) return 'New password should be at least 8 symbols';
          return undefined;
        })();

  const keepingPassword = newPassword === undefined;

  const handleKeepPasswordToggle = useCallback(
    () => setNewPassword(newPassword === undefined ? '' : undefined),
    [newPassword],
  );

  const handleEmailChange = useCallback(({ currentTarget: { value } }) => {
    setEmail(value);
  }, []);

  const handlePasswordChange = useCallback(({ currentTarget: { value } }) => {
    setPassword(value);
  }, []);

  const handleNewPasswordChange = useCallback(({ currentTarget: { value } }) => {
    setNewPassword(value);
  }, []);

  const handleMigrate = useMemo(
    () =>
      !emailError && !newPasswordError
        ? async () => {
            setInProgress(true);
            setLastError(undefined);
            try {
              await api.post('/auth/MigrateFrom219ApiToAuth0', {
                email,
                password,
                newPassword,
              });
              window.location.href = '/';
            } catch (error) {
              const { response: { data: { message = '' } = {} } = {} } = error;
              setLastError(message || error.message);
            }
            setInProgress(false);
          }
        : undefined,
    [emailError, newPasswordError, email, password, newPassword],
  );

  return (
    <form style={{ maxWidth: '40em', margin: '2em auto' }}>
      <FormGroup validationState={emailError ? 'error' : undefined}>
        <ControlLabel>Email</ControlLabel>
        <FormControl
          value={email}
          onChange={handleEmailChange}
          name="email"
          readOnly={inProgress}
        />
        {emailError && <HelpBlock>{emailError}</HelpBlock>}
      </FormGroup>
      <FormGroup>
        <ControlLabel>Password</ControlLabel>
        <FormControl
          value={password}
          type="password"
          name="password"
          onChange={handlePasswordChange}
          readOnly={inProgress}
        />
      </FormGroup>
      {keepingPassword && (
        <FormGroup>
          <Button bsStyle="link" onClick={handleKeepPasswordToggle}>
            Also set new password
          </Button>
        </FormGroup>
      )}
      {!keepingPassword && (
        <FormGroup validationState={newPasswordError ? 'error' : undefined}>
          <ControlLabel>New password</ControlLabel>

          <InputGroup>
            <FormControl
              value={newPassword}
              type="password"
              onChange={handleNewPasswordChange}
              readOnly={inProgress}
            />
            <InputGroup.Button>
              <Button onClick={handleKeepPasswordToggle}>Keep current</Button>
            </InputGroup.Button>
          </InputGroup>
          {newPasswordError && <HelpBlock>{newPasswordError}</HelpBlock>}
        </FormGroup>
      )}
      <Button
        onClick={handleMigrate}
        style={{ margin: '1em auto' }}
        bsSize="large"
        disabled={!handleMigrate || inProgress}
        bsStyle="primary"
      >
        Migrate
      </Button>
      {inProgress && <ProgressBar now={100} active={true} />}
      {lastError && <Alert bsStyle="danger">{lastError}</Alert>}
    </form>
  );
};
