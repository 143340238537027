import React from 'react';
import { omit } from 'lodash';
import { Field, Formik, FormikProps, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Grid, Row, Alert } from 'react-bootstrap';
import ErrorBoundary from '../../../components/common/ErrorBoundary';
import NCBAddendum, { ncbValidators } from '../DealerFormFieldGroups/NCBAddendum';
import ProductionIncentive, {
  productionIncentiveValidations,
} from '../DealerFormFieldGroups/ProductionIncentive';
import RetailPack, { retailPackValidators } from '../DealerFormFieldGroups/RetailPack';
import AppointedProducts, {
  appointedProductValidators,
} from '../DealerFormFieldGroups/AppointedProducts';
import ValidationSelect from '../../../components/forms/inputs/ValidationSelect';
import { RateType, rateTypeOptions } from '../constants';
import * as dealerService from '../../../services/dealerService';
import useDealerContext from '../DealerContext';
import { UpgradeDealerInformationValues } from './UpgradeDealerInformation';
import { asn, products, supplementalProducts } from '../validationSchemas';

const sharedSchema = {
  ...appointedProductValidators,
  ...retailPackValidators,
  ...productionIncentiveValidations,
  ...ncbValidators,
  appointedProducts: products()
    .label('Appointed products')
    .defined(),
  supplementalProducts: supplementalProducts()
    .label('Supplemental products')
    .notRequired(),
  asn: asn.notRequired(),
};

export const upgradeDealerProductSchema = Yup.object()
  .shape(
    // individualIncentivePayee is an internal field - we dont want to expose validation for it outside this form
    omit(sharedSchema, 'individualIncentivePayee'),
  )
  .defined();

const upgradeDealerProductSchemaInternal = Yup.object()
  .shape(sharedSchema)
  .defined();

export interface UpgradeDealerProductValues
  extends Yup.Asserts<typeof upgradeDealerProductSchema> {}

const useFormData = (): {
  showFields: Set<'specialRates'>;
  upgradeEligibleProducts: dealerService.ProductUpgradeOptions;
  onAppointedProductsChanged(products: dealerService.DealerProduct[]): void;
} => {
  const { values } = useFormikContext<UpgradeDealerProductValues>();
  const {
    upgradeEligibleProducts: defaultUpgradeEligibleProducts,
    dealerDetails,
  } = useDealerContext();

  const upgradeEligibleProducts = dealerService.hooks.useMergeSelectedProductWithUpgradeOptions(
    values.appointedProducts,
    defaultUpgradeEligibleProducts,
  );

  const onAppointedProductsChanged = dealerService.hooks.useOnUpgradeDealerAppointedProductsChanged(
    dealerDetails,
  );

  const showFields = new Set<'specialRates'>();

  if (values.rateType === RateType.SPECIAL) {
    showFields.add('specialRates');
  }

  return { showFields, upgradeEligibleProducts, onAppointedProductsChanged };
};

/**
 * ensure sales rep has selected at least one upgrade product
 */
function ensureAtLeastOneUpgradeSelected(values: UpgradeDealerProductValues): boolean {
  const hasAppointedProducts = values.appointedProducts.length >= 1;
  const hasSupplementalProducts = values.supplementalProducts.length >= 1;
  const hasRetailPackProducts = values.retailPackProducts.length >= 1;
  const hasNcbAddendumProducts = values.ncbAddendumProducts.length >= 1;
  const hasProductionIncentiveProducts = values.productionIncentiveProducts.length >= 1;
  return (
    hasAppointedProducts ||
    hasSupplementalProducts ||
    hasRetailPackProducts ||
    hasNcbAddendumProducts ||
    hasProductionIncentiveProducts ||
    values.isGapPlus ||
    values.isBHPH
  );
}

function ProductFields({
  onSubmit,
  children,
}: {
  onSubmit: (values: UpgradeDealerProductValues) => void;
  children: React.ReactElement;
}): React.ReactElement {
  const { showFields, upgradeEligibleProducts, onAppointedProductsChanged } = useFormData();

  return (
    <form onSubmit={onSubmit}>
      <ErrorBoundary>
        <AppointedProducts
          appointedProductOptions={upgradeEligibleProducts.APPOINTED}
          supplementalProductOptions={upgradeEligibleProducts.SUPPLEMENTAL}
          onAppointedProductsChanged={onAppointedProductsChanged}
        />

        <RetailPack retailPackProductsOptions={upgradeEligibleProducts.RETAIL_PACK} />

        <Grid fluid>
          <Row>
            <Field
              name="rateType"
              component={ValidationSelect}
              options={rateTypeOptions}
              label="Will the dealer be using standard rate or special rates?"
              placeholder="Please choose an option"
            />
          </Row>
        </Grid>

        {showFields.has('specialRates') && (
          <>
            <hr />
            <NCBAddendum ncbProductOptions={upgradeEligibleProducts.NCB_ADDENDUM} />

            <hr />
            <ProductionIncentive
              productionIncentiveProductsOptions={upgradeEligibleProducts.INCENTIVES}
            />
          </>
        )}
        {children}
      </ErrorBoundary>
    </form>
  );
}

export default function DealerUpgradeProducts({
  initialValues,
  onSubmit,
  children,
}: {
  initialValues?: UpgradeDealerProductValues;
  onSubmit(values: UpgradeDealerProductValues): void;
  children: (props: FormikProps<UpgradeDealerInformationValues>) => React.ReactElement;
}) {
  const [noUpgradeProductsSelectedError, setNoUpgradeProductsSelectedError] = React.useState<
    string | null
  >();

  const formikHandleSubmit = (values: UpgradeDealerProductValues) => {
    if (!ensureAtLeastOneUpgradeSelected(values)) {
      setNoUpgradeProductsSelectedError(
        'No products have been selected for dealer upgrade, please select at least one product',
      );
      return;
    }
    setNoUpgradeProductsSelectedError(null);
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={upgradeDealerProductSchemaInternal}
      onSubmit={formikHandleSubmit}
    >
      {({ handleSubmit, ...props }) => (
        <>
          {noUpgradeProductsSelectedError != null && (
            <Alert className="alert-danger">{noUpgradeProductsSelectedError}</Alert>
          )}
          <ProductFields onSubmit={handleSubmit}>
            {children({ handleSubmit, ...props })}
          </ProductFields>
        </>
      )}
    </Formik>
  );
}
