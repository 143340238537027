export enum DealerProduct {
  VSC = 'VSC',
  GAP = 'GAP',
  TWP = 'TWP',
}

export enum SupplementalProduct {
  PRCO_POWER = 'PRCO_POWER',
  VSC_SLUDGE = 'VSC_SLUDGE',
}

export interface DealerDetails {
  detailId: number;
  repPortalRequestId: number;
  dlid: number;
  dlCompany: string;
  dlOwner: string;
  dlAddress: string;
  dlCityStzip: string;
  dlEmail: string;
  dlPhone: string;
  dlFax: string;
  vscEff: string; // '2002-04-04';
  vscExp: string; // '0000-00-00';
  gapEff: string; // '2012-02-28';
  gapExp: string; // '2013-11-19';
  statementRequested: boolean;
  pdfCreated: boolean;
  emailSent: boolean;
  processDate: string; // '0000-00-00';
  copyToDealer: boolean;
  myUuid: string;
  vscPack: number;
  gapPack: number;
  gapbhph: boolean;
  gapPlus: boolean;
  gaprsvAmt: number;
  gapncb: boolean;
  vscrsvAmt: number;
  vscncb: boolean;
  twpEff: string;
  twpExp: string;
  twpPack: number;
  twprsvAmt?: number;
  twpncb?: boolean;
}

export enum ProductField {
  APPOINTED = 'APPOINTED',
  BHPH = 'BHPH',
  GAP_PLUS = 'GAP_PLUS',
  RETAIL_PACK = 'RETAIL_PACK',
  INCENTIVES = 'INCENTIVES',
  NCB_ADDENDUM = 'NCB_ADDENDUM',
  SUPPLEMENTAL = 'SUPPLEMENTAL',
}

type AllProductFields = Record<
  ProductField.APPOINTED | ProductField.RETAIL_PACK | ProductField.INCENTIVES,
  DealerProduct[]
>;

export type ProductUpgradeOptions = AllProductFields &
  Record<ProductField.NCB_ADDENDUM, DealerProduct.VSC[]> &
  Record<ProductField.GAP_PLUS, boolean> &
  Record<ProductField.BHPH, boolean> &
  Record<ProductField.SUPPLEMENTAL, SupplementalProduct[]>;

export interface DealerDetailsFormInputs {
  prcoDealerId: number;
  dealershipName: string;
  ownerFirstName: string;
  ownerLastName: string;
  dealerContactEmail: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: number;
  telephone: string;
  hasNewProduct: boolean;
}

interface S3FileResult {
  fileKey: string;
  s3Location: string;
  fileName: string;
  fileLink: string;
}

export interface Dealer {
  prcoDealerId?: number;
  dealershipName: string;
  fiManagerFirstName: string;
  fiManagerLastName: string;
  documentSignerFirstName: string;
  documentSignerLastName: string;
  ownerFirstName: string;
  ownerLastName: string;
  yearsInBusiness: number;
  yearsAtLocation: number;
  documentSignersPositionTitle: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: number;
  telephone: number;
  fax: number;
  dealerBusinessType: string;
  dealerEmail: string;
  currentProgram: string;
  bondExpiration: Date;
  accountSource: string;
  dmvNumber: number;
  businessNumber: number;
  issuingEntity: string;
  appointmentDate: Date;
  created: Date;
  updated: Date;
  dealerContactName: string;
  dealerContactEmail: string;
  ownerEmail: string;
  stateLicenseNumber: string;
  businessLicenseNumber: string;
  appointedProducts: DealerProduct[];
  hasNewProduct: boolean;
  isGapPlus: boolean;
  isBHPH: boolean;
  bhphLenderName: string;
  asn: string;
  hasPENIntegration: boolean;
  penIntegrationSystem?: string;
  retailPack: boolean;
  retailPackProducts: DealerProduct[];
  retailPackAmountVSC?: number;
  retailPackAmountGAP?: number;
  retailPackAmountTWP?: number;
  rateType: 'STANDARD' | 'SPECIAL';
  productionIncentive: boolean;
  productionIncentiveProducts: DealerProduct[];
  productionIncentivePayType?:
    | 'DEALER_ONLY'
    | 'SPLIT_DEALER_INDIVIDUAL'
    | 'INDIVIDUAL_ONLY'
    | 'SPLIT_INDIVIDUALS';
  incentiveAmountVSC?: number;
  incentivePayeeVSC?: string[];
  incentiveAmountGAP?: number;
  incentivePayeeGAP?: string[];
  incentiveAmountTWP?: number;
  incentivePayeeTWP?: string[];
  ePrcoAccountEmail?: string;
  stateDealerLicenseEffectiveDate?: string;
  stateDealerLicenseExpirationDate?: string;
  includeNCBAddendum: boolean;
  ncbAddendumProducts: DealerProduct[];
  specialRateTypeNotes?: string;
  dealerW9Forms: S3FileResult[];
  notes: string;
  supplementalProducts: SupplementalProduct[];
  prcoPowerLicenseFee?: number;
}
