import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useAuth0, Auth0Provider } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import cookie from 'js-cookie';
import { Alert } from 'react-bootstrap';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history3/redirect';
import { ProgressBar } from 'react-bootstrap';
import {
  saveAuthDataToStore,
  resetAuthState,
  setAuthLoading,
  loadCurrentUserData,
} from '../redux/modules/auth';
import NotificationModal from './common/NotificationModal';

const AuthContextDefault = {
  login: undefined,
  reload: undefined,
  logout: undefined,
};

export const AuthContext = createContext(AuthContextDefault);

const returnTo = window.location.origin;

const auth0Props = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: window.location.origin,
  returnTo,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  scope: process.env.REACT_APP_AUTH0_SCOPE,
};

const Auth0Wrap = ({ children, saveAuthDataToStore, resetAuthState, setAuthLoading }) => {
  const {
    user,
    error,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout: auth0Logout,
  } = useAuth0();

  // Indicates that auth data is loading
  const [reloading, setReloading] = useState(false);

  // reload auth data from server once auth0 user is available
  const [autoReload, setAutoReload] = useState(true);

  useEffect(() => {
    if (!user) {
      setAutoReload(true);
    }
  }, [user]);

  const login = loginWithRedirect;

  const logout = useMemo(
    () => ((user || error) && auth0Logout ? () => auth0Logout({ returnTo }) : undefined),
    [user, error, auth0Logout],
  );

  const reload = useMemo(
    () =>
      user
        ? async () => {
            if (error) {
              resetAuthState();
              auth0Logout({
                returnTo,
              });
              return;
            }
            setReloading(true);
            setAutoReload(false);
            await getAccessTokenSilently();
            const claims = await getIdTokenClaims();
            const token = claims.__raw;
            cookie.set('auth0Token', token);
            try {
              const { user } = await loadCurrentUserData();
              saveAuthDataToStore(undefined, {
                user,
              });
              setReloading(false);
            } catch (error) {
              setReloading(false);
              saveAuthDataToStore(error);
            }
          }
        : undefined,
    [
      user,
      error,
      auth0Logout,
      getAccessTokenSilently,
      getIdTokenClaims,
      saveAuthDataToStore,
      resetAuthState,
    ],
  );

  useEffect(() => {
    setAuthLoading({ isLoading: reloading || isLoading || (!!user && autoReload) });
  }, [reloading, isLoading, setAuthLoading, user, autoReload]);

  useEffect(() => {
    if (error) {
      saveAuthDataToStore(error);
    }
  }, [error, saveAuthDataToStore]);

  useEffect(() => {
    if (reload) {
      reload();
    }
  }, [reload]);

  const contextValue = useMemo(
    () => ({
      login,
      reload,
      logout,
    }),
    [login, reload, logout],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const AuthErrorPopup = ({ error, isLoading }) => {
  const { reload, logout } = useContext(AuthContext);

  return (
    <NotificationModal
      title="Authentication failed"
      show={!!error}
      confirmText="Try again"
      disabledConfirm={isLoading || !reload}
      onConfirm={reload}
      cancelText="Logout"
      disabledCancel={!logout}
      onCancel={logout}
    >
      <p>
        This may happen due to network connectivity issue, or we may have trouble on our end. You
        can try:
      </p>
      <ul>
        <li>Check your network connection and try again</li>
        <li>Logout, and then login back</li>
      </ul>
      <Alert>{error}</Alert>
    </NotificationModal>
  );
};

export const AuthProviderComponent = props => {
  const { error, isLoading, saveAuthDataToStore, resetAuthState, setAuthLoading, children } = props;

  return (
    <Auth0Provider {...auth0Props}>
      <Auth0Wrap
        saveAuthDataToStore={saveAuthDataToStore}
        resetAuthState={resetAuthState}
        setAuthLoading={setAuthLoading}
      >
        <AuthErrorPopup error={error} isLoading={isLoading} />
        {children}
      </Auth0Wrap>
    </Auth0Provider>
  );
};

export const AuthProvider = connect(
  ({ auth: { isLoading, error } }) => ({
    isLoading,
    error,
  }),
  {
    saveAuthDataToStore,
    resetAuthState,
    setAuthLoading,
  },
)(AuthProviderComponent);

export const makeRouterRedirects = () => {
  const authDefaults = {
    authenticatingSelector: state => !state.auth.initialized || state.auth.isLoading,
    AuthenticatingComponent: () => <ProgressBar now={100} active={true} />,
  };

  // Authenticated routes
  // eslint-disable-next-line no-unused-vars
  const userAuth = connectedRouterRedirect({
    ...authDefaults,
    redirectPath: '/',
    authenticatedSelector: state =>
      !authDefaults.authenticatingSelector(state) && !!state.auth.user,
    wrapperDisplayName: 'UserIsAuthenticated',
  });

  // eslint-disable-next-line no-unused-vars
  const prcoAdminAuth = connectedRouterRedirect({
    ...authDefaults,
    redirectPath: '/',
    authenticatedSelector: state =>
      !authDefaults.authenticatingSelector(state) &&
      !!state.auth.user &&
      _.get(state.auth.user, 'salesRepRel.prcoAdmin'),
    wrapperDisplayName: 'AdminIsAuthenticated',
  });

  return {
    userAuth,
    prcoAdminAuth,
  };
};
