import { useCallback, useMemo } from 'react';
import moment from 'moment';
import qs from 'qs';
import _ from 'lodash';
import {
  DealerOwnershipStructure,
  DealerSoftware,
  ProductionIncentiveSplitType,
  RateType,
} from './constants';
import { STATES } from '../../constants/general';
import {
  ACCOUNT_NUMBER_BANK,
  ADDRESS_BANK,
  CITY_BANK,
  DEALER_ID_BANK,
  DEALERSHIP_NAME_BANK,
  EMAIL_BANK,
  FIRST_NAME_BANK,
  getEmailOverride,
  getNameOverride,
  LAST_NAME_BANK,
  NAME_BANK,
  ZIP_BANK,
} from '../../utils/testData';
import convertBooleanString from '../../utils/convertBooleanString';
import { UpgradeDealerFormInputsByPage, UpgradeDealerPage } from './UpgradeDealer/types';
import { NewDealerFormInputsByPage, NewDealerPage } from './NewDealer/types';
import * as dealerService from '../../services/dealerService';
import { Dealer } from '../../services/dealerService';

const getAppointedProducts = formValues => {
  if (dealerService.hasGAPProduct(formValues.appointedProducts)) {
    return {
      ...formValues,
      isGapPlus: _.sample([true, false]),
      isBHPH: _.sample([true, false]),
      bhphLenderName: _.sample(DEALERSHIP_NAME_BANK),
    };
  }
  return formValues;
};

const getRateSpecificForm = formValues => {
  if (
    formValues.rateType !== RateType.STANDARD &&
    (dealerService.hasGAPProduct(formValues.appointedProducts) ||
      dealerService.hasTWPProduct(formValues.appointedProducts))
  ) {
    return {
      ...formValues,
      productionIncentive: true,
      productionIncentiveProducts: formValues.appointedProducts,
      incentiveAmountVSC: 1000,
      incentivePayeeVSC: _.sampleSize(NAME_BANK, _.random(1, 2)).join(', '),
      incentiveAmountGAP: 1000,
      incentivePayeeGAP: _.sampleSize(NAME_BANK, _.random(1, 2)).join(', '),
      incentiveAmountTWP: 1000,
      incentivePayeeTWP: _.sampleSize(NAME_BANK, _.random(1, 2)).join(', '),
      productionIncentivePayType: _.sample(Object.values(ProductionIncentiveSplitType)),
      dealerW9Forms: [],
      specialRateTypeNotes: 'Test Data, auto fill.',
      notes: 'Test Data, auto fill notes.',
    };
  }
  return { ...formValues, productionIncentive: false };
};

const getRetailSpecificForm = formValues => {
  if (formValues.retailPack) {
    return {
      ...formValues,
      retailPackProducts: formValues.appointedProducts,
      ...(dealerService.hasVSCProduct(formValues.appointedProducts) && {
        retailPackAmountVSC: 123,
      }),
      ...(dealerService.hasGAPProduct(formValues.appointedProducts) && {
        retailPackAmountGAP: 123,
      }),
      ...(dealerService.hasTWPProduct(formValues.appointedProducts) && {
        retailPackAmountTWP: 123,
      }),
    };
  }
  return formValues;
};

function useTestData(queryString: string): Dealer | null {
  return useMemo((): Dealer | null => {
    const parsedQuery = _.mapValues(qs.parse(
      queryString,
      { ignoreQueryPrefix: true },
    ), (value) => convertBooleanString(value))

    if (!parsedQuery.testData) {
      return null;
    }
    const testProducts = parsedQuery.products != null ? (parsedQuery.products as string).split(',') : null;
    const selected =
      testProducts ?? _.sampleSize(Object.values(dealerService.DealerProduct), _.random(1, 3));
    const penIntegration = _.sample([
      {
        hasPENIntegration: true,
        penIntegrationSystem: 'mocked PEN system',
      },

      { hasPENIntegration: false },
    ]);

    const allDealerInfo = {
      dealershipName: `TEST ${_.sample(DEALERSHIP_NAME_BANK)}`,
      dealerBusinessType: _.sample(Object.values(DealerOwnershipStructure)),
      documentSignerFirstName: getNameOverride(true, false) || _.sample(FIRST_NAME_BANK),
      documentSignerLastName: getNameOverride(false, true) || _.sample(LAST_NAME_BANK),
      fiManagerFirstName: getNameOverride(true, false) || _.sample(FIRST_NAME_BANK),
      fiManagerLastName: getNameOverride(false, true) || _.sample(LAST_NAME_BANK),
      ownerFirstName: getNameOverride(true, false) || _.sample(FIRST_NAME_BANK),
      ownerLastName: getNameOverride(false, true) || _.sample(LAST_NAME_BANK),
      yearsInBusiness: _.random(10, 40),
      yearsAtLocation: _.random(5, 10),
      documentSignersPositionTitle: _.sample(['Test Manager', 'Test Assistant Manager', 'Test GM']),

      // Contact Info
      streetAddress: _.sample(ADDRESS_BANK),
      city: _.sample(CITY_BANK),
      state: _.sample(Object.keys(STATES)),
      zip: _.sample(ZIP_BANK),
      telephone: _.sample(['8884562439', '1234567890']),
      dealerContactEmail: getEmailOverride('dealer') || _.sample(EMAIL_BANK),
      ownerEmail: getEmailOverride('owner') || _.sample(EMAIL_BANK),
      ePrcoAccountEmail: getEmailOverride('e-prco') || _.sample(EMAIL_BANK),

      // Dealer Context
      currentProgram: _.sample(['Premium VIP', 'Extra Savings']),
      bondExpiration: '12/24/2023',
      accountSource: _.sample(['Referral', 'Cold calling']),
      stateLicenseNumber: _.sample(ACCOUNT_NUMBER_BANK.map(n => `sln_${n}`)),
      stateDealerLicenseEffectiveDate: moment()
        .subtract(6, 'months')
        .format('MM/DD/YYYY'),
      stateDealerLicenseExpirationDate: moment()
        .add(30, 'days')
        .format('MM/DD/YYYY'),
      businessLicenseNumber: _.sample(DEALER_ID_BANK.map(n => `bln_${n}`)),
      issuingEntity: _.sample(['Government', 'Independent']),
      appointmentDate: moment().format('MM/DD/YYYY'),

      // Dealer products
      appointedProducts: selected,
      supplementalProducts: [dealerService.SupplementalProduct.PRCO_POWER],
      prcoPowerLicenseFee: 123,
      asn: _.sample(Object.values(DealerSoftware)),
      ...penIntegration,
      retailPack: _.sample([true, false]),
      rateType: _.sample(Object.values(RateType)),
    };
    const cleaner = _.flow(getAppointedProducts, getRateSpecificForm, getRetailSpecificForm);
    return cleaner(allDealerInfo);
  }, [queryString]);
}

export default function useDealerTestData(): {
  getNewDealerTestData(): NewDealerFormInputsByPage;
  getUpgradeDealerTestData(
    dealer: Pick<Dealer, 'dealerContactEmail'>,
  ): UpgradeDealerFormInputsByPage;
} {
  const queryString = window.location.search;
  const testData = useTestData(queryString);
  const getNewDealerTestData = useCallback(() => {
    if (testData == null) {
      return null;
    }
    return {
      [NewDealerPage.DEALER_INFORMATION]: {
        dealershipName: testData.dealershipName,
        dealerBusinessType: testData.dealerBusinessType,
        fiManagerFirstName: testData.fiManagerFirstName,
        fiManagerLastName: testData.fiManagerLastName,
        yearsInBusiness: testData.yearsInBusiness,
        yearsAtLocation: testData.yearsAtLocation,
        documentSignerFirstName: testData.documentSignerFirstName,
        documentSignerLastName: testData.documentSignerLastName,
        documentSignersPositionTitle: testData.documentSignersPositionTitle,
        dealerContactEmail: testData.dealerContactEmail,
        notes: testData.notes,
      },
      [NewDealerPage.DEALER_CONTACT]: {
        streetAddress: testData.streetAddress,
        city: testData.city,
        state: testData.state,
        zip: testData.zip,
        telephone: testData.telephone,
        ownerFirstName: testData.ownerFirstName,
        ownerLastName: testData.ownerLastName,
        ownerEmail: testData.ownerEmail,
        ePrcoAccountEmail: testData.ePrcoAccountEmail,
        notes: testData.notes,
      },
      [NewDealerPage.DEALER_CONTEXT]: {
        currentProgram: testData.currentProgram,
        bondExpiration: testData.bondExpiration,
        accountSource: testData.accountSource,
        stateLicenseNumber: testData.stateLicenseNumber,
        stateDealerLicenseEffectiveDate: testData.stateDealerLicenseEffectiveDate,
        stateDealerLicenseExpirationDate: testData.stateDealerLicenseExpirationDate,
        businessLicenseNumber: testData.businessLicenseNumber,
        issuingEntity: testData.issuingEntity,
        appointmentDate: testData.appointmentDate,
      },
      [NewDealerPage.DEALER_PRODUCTS]: {
        appointedProducts: testData.appointedProducts,
        supplementalProducts: testData.supplementalProducts,
        prcoPowerLicenseFee: testData.prcoPowerLicenseFee,
        asn: testData.asn,
        isGapPlus: testData.isGapPlus,
        isBHPH: testData.isBHPH,
        bhphLenderName: testData.bhphLenderName,
        retailPack: testData.retailPack,
        retailPackProducts: testData.retailPackProducts,
        retailPackAmountVSC: testData.retailPackAmountVSC,
        retailPackAmountGAP: testData.retailPackAmountGAP,
        retailPackAmountTWP: testData.retailPackAmountTWP,
        rateType: testData.rateType,
        productionIncentive: testData.productionIncentive,
        productionIncentiveProducts: testData.productionIncentiveProducts,
        productionIncentivePayType: testData.productionIncentivePayType,
        incentiveAmountVSC: testData.incentiveAmountVSC,
        incentivePayeeVSC: testData.incentivePayeeVSC,
        incentiveAmountGAP: testData.incentiveAmountGAP,
        incentivePayeeGAP: testData.incentivePayeeGAP,
        incentiveAmountTWP: testData.incentiveAmountTWP,
        incentivePayeeTWP: testData.incentivePayeeTWP,
        dealerW9Forms: testData.dealerW9Forms,
        hasPENIntegration: testData.hasPENIntegration,
        penIntegrationSystem: testData.penIntegrationSystem,
        specialRateTypeNotes: testData.specialRateTypeNotes,
      },
    };
  }, [testData]);
  const getUpgradeDealerTestData = useCallback(
    dealerDefaults => {
      if (testData == null) {
        return null;
      }

      const data = _.pick(testData, [
        'documentSignerFirstName',
        'documentSignerLastName',
        'documentSignersPositionTitle',
        'dealerContactEmail',
        'ownerEmail',
        'appointmentDate',
        'hasPENIntegration',
        'penIntegrationSystem',
        'notes',

        'appointedProducts',
        'isGapPlus',
        'isBHPH',
        'bhphLenderName',
        'retailPack',
        'retailPackProducts',
        'retailPackAmountVSC',
        'retailPackAmountGAP',
        'retailPackAmountTWP',
        'includeNCBAddendum',
        'ncbAddendumProducts',
        'productionIncentive',
        'productionIncentiveProducts',
        'productionIncentivePayType',
        'incentiveAmountVSC',
        'incentivePayeeVSC',
        'incentiveAmountGAP',
        'incentivePayeeGAP',
        'dealerW9Forms',
        'hasPENIntegration',
        'penIntegrationSystem',
        'specialRateTypeNotes',
      ]);
      const ncb = _.sample([
        {
          includeNCBAddendum: true,
          penIntegrationSystem: ['VSC'],
        },

        { includeNCBAddendum: false },
      ]);

      return {
        [UpgradeDealerPage.DEALER_INFORMATION]: {
          ...dealerDefaults,
          appointmentDate: new Date(),
          documentSignerFirstName: data.documentSignerFirstName,
          documentSignerLastName: data.documentSignerLastName,
          documentSignersPositionTitle: data.documentSignersPositionTitle,
          ownerEmail: data.ownerEmail,
          hasPENIntegration: data.hasPENIntegration,
          penIntegrationSystem: data.penIntegrationSystem,
          notes: data.notes,
        },
        [UpgradeDealerPage.DEALER_PRODUCTS]: {
          appointedProducts: data.appointedProducts,
          isGapPlus: data.isGapPlus,
          isBHPH: data.isBHPH,
          bhphLenderName: data.bhphLenderName,
          retailPack: data.retailPack,
          retailPackProducts: data.retailPackProducts,
          retailPackAmountVSC: data.retailPackAmountVSC,
          retailPackAmountGAP: data.retailPackAmountGAP,
          retailPackAmountTWP: data.retailPackAmountTWP,
          includeNCBAddendum: data.includeNCBAddendum,
          ncbAddendumProducts: data.ncbAddendumProducts,
          productionIncentive: data.productionIncentive,
          productionIncentiveProducts: data.productionIncentiveProducts,
          productionIncentivePayType: data.productionIncentivePayType,
          incentiveAmountVSC: data.incentiveAmountVSC,
          incentivePayeeVSC: data.incentivePayeeVSC,
          incentiveAmountGAP: data.incentiveAmountGAP,
          incentivePayeeGAP: data.incentivePayeeGAP,
          dealerW9Forms: data.dealerW9Forms,
          hasPENIntegration: data.hasPENIntegration,
          penIntegrationSystem: data.penIntegrationSystem,
          specialRateTypeNotes: data.specialRateTypeNotes,
          // NCB is upgrade only:
          ...ncb,
        },
      };
    },
    [testData],
  );
  return { getNewDealerTestData, getUpgradeDealerTestData };
}
