import React from 'react';
import * as Yup from 'yup';
import { Formik, Field, FormikProps } from 'formik';
import ErrorBoundary from '../../../components/common/ErrorBoundary';
import ValidationInput from '../../../components/forms/inputs/ValidationInput';
import ValidationSelect from '../../../components/forms/inputs/ValidationSelect';
import { STATES, statesForSelection } from '../../../constants/general';
import FirstLastNameInputs from '../DealerFormFieldGroups/FirstLastNameInputs';
import { validateEmail, words } from '../validationSchemas';

/* eslint-disable no-template-curly-in-string */
export const newDealerContactSchema = Yup.object()
  .shape({
    streetAddress: Yup.string()
      .label('Street address')
      .defined('Please provide full address'),
    city: words.label('City').defined('Please provide city name'),
    state: Yup.mixed()
      .oneOf(Object.keys(STATES))
      .label('State')
      .defined(),
    zip: Yup.string()
      .label('Zip')
      .min(5, 'Zip must be at least 5 digits')
      .max(10, 'Zips should not be longer than 10 digits')
      .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Zip must be in format ##### or #####-####')
      .defined(), // needs to be string, zip codes can prefixed with 00, smallest is 00501
    telephone: Yup.string()
      .label('Telephone number')
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        'Phone number must be ###-###-#### or ##########',
      )
      .defined('Phone number must be provided'),
    ownerFirstName: words.label('Owner first name').defined(),
    ownerLastName: words.label('Owner last name').defined(),
    ownerEmail: validateEmail.label('Owner email').defined('Owners email is required'),
    ePrcoAccountEmail: validateEmail
      .label('EPrco account email')
      .defined('ePRco account email must be provided'),
  })
  .defined();

export interface NewDealerContactValues extends Yup.Asserts<typeof newDealerContactSchema> {}

const stateOptions = [{ label: 'Select a state', value: '' }].concat(statesForSelection());

export default function NewDealerContact({
  initialValues,
  onSubmit,
  children,
}: {
  initialValues?: NewDealerContactValues;
  onSubmit(values: NewDealerContactValues): void;
  children: (props: FormikProps<NewDealerContactValues>) => React.ReactElement;
}) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={newDealerContactSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, ...props }) => (
        <form onSubmit={handleSubmit}>
          <ErrorBoundary>
            <Field
              required
              name="streetAddress"
              component={ValidationInput}
              type="text"
              label="Street Address"
            />
            <Field required name="city" component={ValidationInput} type="text" label="City" />
            <Field
              required
              name="state"
              component={ValidationSelect}
              options={stateOptions}
              label="State"
              placeholder="Please choose state"
            />
            <Field required name="zip" component={ValidationInput} type="text" label="Zip" />
            <Field
              required
              name="telephone"
              component={ValidationInput}
              type="text"
              label="Telephone No."
            />
            <hr />

            <FirstLastNameInputs
              label="Owner's Name"
              firstNameProps={{ name: 'ownerFirstName', required: true }}
              lastNameProps={{ name: 'ownerLastName', required: true }}
            />

            <Field
              required
              name="ownerEmail"
              component={ValidationInput}
              type="text"
              label="Owner's Email"
            />
            <Field
              required
              name="ePrcoAccountEmail"
              component={ValidationInput}
              type="text"
              label="DEALER's email for ePRco account"
            />
            {children({ handleSubmit, ...props })}
          </ErrorBoundary>
        </form>
      )}
    </Formik>
  );
}
