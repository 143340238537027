import React from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Grid } from 'react-bootstrap';
import * as Yup from 'yup';
import { yesNoOptions } from '../constants';
import ValidationSelect from '../../../components/forms/inputs/ValidationSelect';
import ValidationInput from '../../../components/forms/inputs/ValidationInput';
import ValidationMultiCheckbox from '../../../components/forms/inputs/ValidationMultiCheckbox';
import { UpgradeDealerProductValues } from '../UpgradeDealer/UpgradeDealerProducts';
import { NewDealerProductsValues } from '../NewDealer/NewDealerProducts';
import * as dealerService from '../../../services/dealerService';
import { products } from '../validationSchemas';

export const retailPackValidators: Record<
  | 'retailPack'
  | 'retailPackProducts'
  | 'retailPackAmountVSC'
  | 'retailPackAmountGAP'
  | 'retailPackAmountTWP',
  Yup.BaseSchema
> = {
  retailPack: Yup.boolean()
    .label('Retail Pack')
    .label('')
    .notRequired(),
  retailPackProducts: products()
    .label('Retail pack products')
    .notRequired()
    .when('retailPack', {
      is: true,
      then: products()
        .required('Please select at least one retail pack product')
        .min(1)
        .required('At least one product must be selected'),
    }),
  retailPackAmountVSC: Yup.number()
    .label('Retail pack amount VSC')
    .notRequired()
    .when('retailPackProducts', {
      is: dealerService.hasVSCProduct,
      then: Yup.number()
        .min(0)
        .required('Please provide an amount'),
    }),
  retailPackAmountGAP: Yup.number()
    .label('Retail pack amount GAP')
    .notRequired()
    .when('retailPackProducts', {
      is: dealerService.hasGAPProduct,
      then: Yup.number()
        .min(0)
        .required('Please provide an amount'),
    }),
  retailPackAmountTWP: Yup.number()
    .label('Retail pack amount TWP')
    .notRequired()
    .when('retailPackProducts', {
      is: dealerService.hasTWPProduct,
      then: Yup.number()
        .min(0)
        .required('Please provide an amount'),
    }),
};

export type RetailPackFields =
  | 'retailPack'
  | 'retailPackProducts'
  | 'retailPackAmountVSC'
  | 'retailPackAmountGAP'
  | 'retailPackAmountTWP';

const useShowFields = (): {
  showFields: Set<RetailPackFields>;
} => {
  const { values } = useFormikContext<NewDealerProductsValues | UpgradeDealerProductValues>();

  const showFields = new Set<RetailPackFields>();

  if (values.appointedProducts != null) {
    showFields.add('retailPack');
  }

  if (values.retailPack) {
    showFields.add('retailPackProducts');
    if (dealerService.hasGAPProduct(values.retailPackProducts)) {
      showFields.add('retailPackAmountGAP');
    }
    if (dealerService.hasVSCProduct(values.retailPackProducts)) {
      showFields.add('retailPackAmountVSC');
    }
    if (dealerService.hasTWPProduct(values.retailPackProducts)) {
      showFields.add('retailPackAmountTWP');
    }
  }

  return { showFields };
};

export default function RetailPack({
  retailPackProductsOptions,
}: {
  retailPackProductsOptions: dealerService.DealerProduct[];
}) {
  const { showFields } = useShowFields();
  return (
    <Grid style={{ padding: 0 }} fluid>
      <Field
        name="retailPack"
        component={ValidationSelect}
        options={yesNoOptions}
        disabled={!showFields.has('retailPack')}
        label="Does the dealer wish to use a retail pack?"
        placeholder="Please choose an option"
      />

      {showFields.has('retailPackProducts') && (
        <FieldArray
          name="retailPackProducts"
          render={props => (
            // @ts-expect-error TS2322
            <ValidationMultiCheckbox
              {...props}
              options={retailPackProductsOptions}
              label="What products will the retail pack be appointed for?"
              placeholder="Please choose an option"
            />
          )}
        />
      )}

      {showFields.has('retailPackAmountVSC') && (
        <Field
          name="retailPackAmountVSC"
          component={ValidationInput}
          type="number"
          min={0}
          label="What should the VSC retail pack amount be?"
        />
      )}

      {showFields.has('retailPackAmountGAP') && (
        <Field
          name="retailPackAmountGAP"
          component={ValidationInput}
          type="number"
          min={0}
          label="What should the GAP retail pack amount be?"
        />
      )}

      {showFields.has('retailPackAmountTWP') && (
        <Field
          name="retailPackAmountTWP"
          component={ValidationInput}
          type="number"
          min={0}
          label="What should the TWP retail pack amount be?"
        />
      )}
    </Grid>
  );
}
