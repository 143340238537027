import { DealerProduct, ProductField, ProductUpgradeOptions, SupplementalProduct } from './types';

export const EMPTY_DATE = '0000-00-00';

const allProducts = [DealerProduct.GAP, DealerProduct.VSC, DealerProduct.TWP];

export const PRCO_PRODUCT_UPGRADE_OPTIONS: Readonly<ProductUpgradeOptions> = Object.freeze({
  [ProductField.APPOINTED]: allProducts,
  [ProductField.BHPH]: true,
  [ProductField.GAP_PLUS]: true,
  [ProductField.RETAIL_PACK]: allProducts,
  [ProductField.INCENTIVES]: allProducts,
  [ProductField.NCB_ADDENDUM]: [DealerProduct.VSC],
  [ProductField.SUPPLEMENTAL]: [SupplementalProduct.PRCO_POWER, SupplementalProduct.VSC_SLUDGE],
});
