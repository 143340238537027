import React from 'react';
import { Field, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import ValidationInput from '../../../components/forms/inputs/ValidationInput';
import ValidationSelect from '../../../components/forms/inputs/ValidationSelect';
import { DealerOwnershipStructure, dealerTypeOptions } from '../constants'; //todo rename
import ErrorBoundary from '../../../components/common/ErrorBoundary';
import DocumentSigner from '../DealerFormFieldGroups/DocumentSigner';
import FirstLastNameInputs from '../DealerFormFieldGroups/FirstLastNameInputs';
import { validateEmail, words } from '../validationSchemas';

export const newDealerInformationSchema = Yup.object()
  .shape({
    dealershipName: Yup.string()
      .defined('Dealership name must be provided')
      .label('Dealership name'),
    dealerBusinessType: Yup.mixed()
      .oneOf(Object.values(DealerOwnershipStructure))
      .defined('Dealership business type must be specified')
      .label('Dealer business type'),
    fiManagerFirstName: words
      .defined('Manager first name must be provided')
      .label('FI manager first name'),
    fiManagerLastName: words
      .defined('Manager last name must be provided')
      .label('FI manager last name'),
    yearsInBusiness: Yup.number()
      .label('Years in business')
      .max(100)
      .min(0)
      .defined('Years in business must be provided'),
    yearsAtLocation: Yup.number()
      .label('Years at location')
      .max(100)
      .min(0)
      .defined('Years at location must be provided'),
    documentSignerFirstName: words
      .defined('Signer first name must be provided')
      .label('Document signer first name'),
    documentSignerLastName: words
      .defined('Signer last name must be provided')
      .label('Document signer last name'),
    documentSignersPositionTitle: Yup.string()
      .defined('Signer title must be provided')
      .label('Document signers position title'),
    dealerContactEmail: validateEmail
      .defined('Please provide a contact email')
      .label('Dealer contact email'),
    notes: Yup.string()
      .notRequired()
      .label('Notes'),
  })
  .defined();

export interface NewDealerInformationValues
  extends Yup.Asserts<typeof newDealerInformationSchema> {}

export default function NewDealerInformation({
  initialValues,
  onSubmit,
  children,
}: {
  initialValues?: NewDealerInformationValues;
  onSubmit(values: NewDealerInformationValues): void;
  children: (props: FormikProps<NewDealerInformationValues>) => React.ReactElement;
}) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={newDealerInformationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, ...props }) => (
        <form onSubmit={handleSubmit}>
          <ErrorBoundary>
            <Field
              name="dealershipName"
              component={ValidationInput}
              type="text"
              required
              label="Dealership Name"
              placeholder="Dealership Name"
            />
            <Field
              name="dealerBusinessType"
              component={ValidationSelect}
              options={dealerTypeOptions}
              required
              label="Dealer Business Type"
            />
            <FirstLastNameInputs
              label="F & I Manager's Name"
              firstNameProps={{ name: 'fiManagerFirstName' }}
              lastNameProps={{ name: 'fiManagerLastName' }}
            />
            <Field
              name="yearsInBusiness"
              component={ValidationInput}
              type="number"
              required
              label="Years Owner in Auto Business"
            />
            <Field
              name="yearsAtLocation"
              component={ValidationInput}
              type="number"
              required
              label="Years Owner in Business at this Location"
            />
            <hr />
            <DocumentSigner />
            {children({ handleSubmit, ...props })}
          </ErrorBoundary>
        </form>
      )}
    </Formik>
  );
}
